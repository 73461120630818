// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vGXX3eiXLp47PXpwGhShXw\\=\\={display:flex;flex-direction:column;position:fixed;inset:0;max-width:1200px;margin:0 auto}.vGXX3eiXLp47PXpwGhShXw\\=\\=.Xt7dvs0COXmkngtmJHrNAg\\=\\={color:#fff;background-color:#222}@media(max-width: 600px){.vGXX3eiXLp47PXpwGhShXw\\=\\={position:relative}}", "",{"version":3,"sources":["webpack://./src/styles/App.module.scss","webpack://./src/styles/constants.scss"],"names":[],"mappings":"AAEA,4BACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,OAAA,CACA,gBAAA,CACA,aAAA,CAEA,uDACE,UCWc,CDVd,qBCGoB,CAAA,yBDbxB,4BAcI,iBAAA,CAAA","sourcesContent":["@import \"./constants.scss\";\n\n.page {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  inset: 0;\n  max-width: 1200px;\n  margin: 0 auto;\n\n  &.dark-mode {\n    color: $color-text-dark;\n    background-color: $color-background-dark;\n  }\n\n  @media (max-width: $max-mobile-width) {\n    position: relative;\n  }\n}\n","$max-small-desktop-width: 700px;\n$max-mobile-width: 600px;\n\n$unit: 10px;\n\n$color-background: #fff;\n$color-cta: #24f;\n$color-answer: rgb(233, 152, 0);\n$color-answer-changed: rgb(233, 66, 0);\n$color-ruler: #f1d0a2;\n$color-warning: #e44;\n$color-selected-background: rgb(245, 200, 117);\n$color-text: #000;\n$color-comment: #2daf16;\n\n$color-background-dark: #222;\n$color-cta-dark: rgb(125, 159, 252);\n$color-answer-dark: rgb(254, 191, 73);\n$color-answer-changed-dark: rgb(246, 83, 19);\n$color-ruler-dark: #6f4a15;\n$color-warning-dark: rgb(238, 87, 87);\n$color-selected-background-dark: rgb(113, 75, 5);\n$color-text-dark: #fff;\n$color-comment-dark: #4fd637;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "vGXX3eiXLp47PXpwGhShXw==",
	"dark-mode": "Xt7dvs0COXmkngtmJHrNAg=="
};
export default ___CSS_LOADER_EXPORT___;
